@import "~/src/assets/style/variables";

.codeEditorContainer {
  height: 100%;
}

.actionButtonContainer {
  position: absolute;
  bottom: 30px;
  right: 30px;
}

.inputName {
  color: $ds-colors-content-secondary;
  margin: 3px 0;
}