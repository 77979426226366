@import "~/src/assets/style/variables";

.container {
  display: flex;
  flex-direction: column;
  padding: 0 16px;
}

.iconCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-upload-wrapper {
  .ant-upload-list {
    max-height: 900px !important;
  }
}

.fileIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: $ds-shapes-rounded_s;
  background-color: $ds-colors-media-gray-gray1;
  overflow: hidden;
  margin: 3px;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.imagePreview {
  width: 100%;
  height: 100%;
  max-height: 500px;
  max-width: 800px;
  object-fit: contain;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
